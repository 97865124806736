import { INodeProps } from '@msdyn365-commerce-modules/utilities';
import { IImageData } from '@msdyn365-commerce/core';
import { ITealiumEventData, ITealiumPromotionData } from './mfrm-shop-by-slider.props.autogenerated';

export const getAnalyticsConfig = (pagePath: string, paragraphText: string, tealiumEvent: ITealiumEventData | undefined, pillPositon: number, toPage: string) => {
    // GENERAL ANALYTICS CONFIG
    return {
        eventName: tealiumEvent?.event,
        eventCategory: tealiumEvent?.event_category,
        eventAction: tealiumEvent?.event_action || `${paragraphText} CTA click - position ${pillPositon ? pillPositon : ''}`,
        eventLabel: tealiumEvent?.event_label || `to: ${toPage}`
    };
};

export const getPromoAnalyticsConfig = (
    image: IImageData | undefined,
    contentBlockAnchorTag: INodeProps | undefined,
    pagePath: string,
    paragraphText: string,
    tealiumPromotion: ITealiumPromotionData | undefined
) => {
    // PROMO ANALYTICS CONFIG
    // Use Configured Paragraph text as Promotion Name
    const promoName = tealiumPromotion?.promotion_name || paragraphText;
    // Use Configured Link as Promotion ID
    const promoId = tealiumPromotion?.promotion_id || contentBlockAnchorTag?.href || '';
    // Use Configured Image URL as default Creative
    const promoCreative = tealiumPromotion?.promotion_creative_version || image?.title || image?.altText || '';
    const promoPosition = tealiumPromotion?.promotion_position || undefined;

    return {
        eventLabel: `${promoName} from ${pagePath}`,
        promotionName: [promoName],
        promotionId: [promoId],
        promotionCreative: [promoCreative],
        promotionPosition: [promoPosition]
    };
};
